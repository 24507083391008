'use client';

import * as Sentry from '@sentry/nextjs';
import type Error from 'next/error';
import { useEffect } from 'react';
import { ROUTES } from '@/config/router';
import { Link } from '@/navigation';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <main>
          <h1>Opps, something went wrong</h1>
          <h5>Please try again later.</h5>
          {error.digest ? (
            <p>
              Error digest: <code>{error.digest}</code>
            </p>
          ) : null}

          <div>
            <button onClick={() => reset()}>Refresh the page</button>
            <Link href={ROUTES.home} passHref>
              <button>Back to Homepage</button>
            </Link>
          </div>
        </main>
      </body>
    </html>
  );
}
